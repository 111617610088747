<template>
  <div>
    <div id="app">
      <lottie
        :options="defaultOptions"
        :height="height"
        v-on:animCreated="handleAnimation"
      />
      <lottie
        :options="defaultOptions"
        :height="height"
        v-on:animCreated="handleAnimation"
        class="bottom"
        :style="{ top }"
      />
      <div class="blackblock" :style="{ top, height: height_px }">
        <div class="white_up"></div>
        <div class="dark_down"></div>
      </div>
      <div class="hello">
        <h3>Producter · Designer · Developer</h3>

        <ul>
          <!-- <li>
            <a href="https://yys.jellyl.com" target="_blank" class="a_link"
              >鉴宝屋</a
            >
          </li>
          <li>
            <a href="https://t.me/JellySharpL" target="_blank" class="a_link"
              >Telegram</a
            >
          </li> -->
          <li>
            <a href="https://github.com/jellyl" target="_blank" class="a_link"
              >GitHub</a
            >
          </li>
          <li>
            <a href="https://dribbble.com/JellyL" target="_blank" class="a_link"
              >Dribbble</a
            >
          </li>
          <!-- <li>
            <a
              href="https://twitter.com/JellySharpL"
              target="_blank"
              class="a_link"
              >Twitter</a
            >
          </li> -->
        </ul>
      </div>
    </div>

    <div class="footer">
      <a class="a_last" href="https://beian.miit.gov.cn/"
        >粤ICP备19038470号-1</a
      >
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie'
import animationData from './assets/jellyl.json'

export default {
  name: 'App',
  data() {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: true,
        autoplay: true,
      },
      animationSpeed: 1,
      // anim: {},
    }
  },
  components: {
    lottie: Lottie,
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },
  },
  mounted() {},
  computed: {
    height: function () {
      return window.innerHeight / 2
    },
    top: function () {
      return this.height / 3 + 'px'
    },
    height_px: function () {
      return this.height + 'px'
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #7c7c7c;
  margin-top: 60px;
  width: 100%;
  // background-color: #2c3e50;
}
body {
  background-color: rgb(8, 8, 11);
  overflow-x: hidden;
  color: #a8a8a8;
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
  width: 100%;
}
.bottom {
  position: absolute;
  opacity: 0.3;
  transform: rotateX(180deg);
  // transform: rotateY(180deg);
  filter: blur(10px);
  pointer-events: none;
  cursor: not-allowed;
  z-index: -1;
}
.blackblock {
  position: absolute;
  width: 100%;
  pointer-events: none;
  cursor: not-allowed;
  z-index: -1;
}
.white_up {
  height: 50%;
  width: 100%;
  pointer-events: none;
  cursor: not-allowed;
  z-index: -1;
}
.dark_down {
  background-image: linear-gradient(
    rgb(8, 8, 11, 0),
    rgb(8, 8, 11, 30),
    rgb(8, 8, 11, 100)
  );
  height: 40%;
  width: 100%;
  pointer-events: none;
  cursor: not-allowed;
  z-index: -1;
}
.footer {
  width: 100%;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  // height: 40px;
  overflow: hidden;
  zoom: 1;
  margin: 0;
  text-align: center;
  // line-height: 40px;
  .a_last {
    font-size: 0.5rem;
    color: #7a7a7a;
    text-decoration: none;
  }
}
.a_link {
  color: #969696;
  font-size: 1 rem;
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  border-bottom: 1px dashed #969696;
}
h3 {
  z-index: 1;
}
.hello {
  z-index: 1;
}
</style>
